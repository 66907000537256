import React, { useState, useEffect } from "react"

function CounterUp({ data, offset, triggered }) {
  let number = data.number
  let duration = 1
  const [count, setCount] = useState("0")
//   if (triggered <= offset) {
//     console.log("entered", " offset", offset)
//   } else {
//     console.log("out", "triggered", triggered)
//   }
  useEffect(() => {
    let start = 0
    // first three numbers from props
    const end = parseInt(number.substring(0, 3))
    // if zero, return
    if (start === end) return
    // find duration per increment
    let totalMilSecDur = parseInt(duration)
    let incrementTime = (totalMilSecDur / end) * 1000
    // timer increments start counter
    // then updates count
    // ends if start reaches end
    if (triggered <= offset) {
      let timer = setInterval(() => {
        start += 1
        setCount(start)
        if (start === end) clearInterval(timer)
      }, incrementTime)
    } else {
        setCount(0)
    }
    // dependency array
  }, [number, duration])

  return (
    <div className="textCenter ">
      <p className="kpiTitle">
        {count} {data.suf ? data.suf : null}
      </p>
      <p className="textP">{data.label}</p>
    </div>
  )
}

export default CounterUp
